const requestApi = require("@/lib/request");
// 管理员接口
module.exports = {
    //客户列表
    vipList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签分组列表
    labelGroupList() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-group-list').then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签分组表单
    labelGroupForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-group-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签分组删除
    labelGroupDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-group-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签列表
    labelList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签表单
    labelForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签排序
    labelOrder(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-order', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //标签删除
    labelDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/label-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //预约列表
    tableList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/table-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //充值列表
    rechargeList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/recharge-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //消费列表
    consumeList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/consume-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //余额列表
    balanceLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/balance-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}